.no-page-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Volledige hoogte van het scherm */
    width: 100vw;
    text-align: center;
  }
  
  .no-page-container img {
    width: 200px; /* Pas de breedte van de afbeelding aan */
    margin-bottom: 20px; /* Ruimte tussen afbeelding en tekst */
  }
  
  .no-page-container h2 {
    font-size: 24px; /* Grotere tekst */
    color: #c0c0c0; /* Kies een kleur naar wens */
  }
  
  .Home-button {
    position: relative;
    background: #ffffff;
    color: #000;
    padding: px;
    margin: 10px;
    border-radius: 10px;
    width: 150px;
    height: 50px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .Popup {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .Popup::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .Home-button:hover .Popup {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  svg:hover span,
  svg:hover .Popup {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .Home-button:hover,
  .Home-button:hover .Popup,
  .Home-button:hover .Popup::before {
    background: linear-gradient(320deg, rgb(54, 54, 54), rgb(56, 56, 56));
    color: #ffffff;
  }