/* DarkModeToggle.css */
.dark-mode-toggle {
    background-color: transparent;
    border: none;
    border-radius: 50%; /* Makes the button round */
    width: 50px; /* Set width */
    height: 50px; /* Set height */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    outline: none; /* Remove outline */
}

.dark-mode-toggle:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light background on hover */
}

.dark-mode-toggle:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); /* Optional focus style */
}

.dark-mode-toggle .svg-inline--fa {
    color: #ffcc00; /* Change icon color */
    font-size: 24px; /* Set icon size */
}
/* App.css */
body.light-mode {
    background-color: white;
    color: black;
}

body.dark-mode {
    background-color: #222;
    color: white;
}

.main-menu.dark-mode {
    background-color: #222;
    color: white;
}

/* Additional styles for your application elements */
.header {
    background-color: var(--header-bg, white);
    color: var(--header-color, black);
}

.dark-mode .header {
    --header-bg: #222;
    --header-color: white;
}

/* Button styles */
.dark-mode-toggle {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}
