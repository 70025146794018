/* General navbar styling */
.navbar {
    display: flex;
    position: relative;
    z-index: 1000;
}

/* Sidebar styling for desktop */
.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 60px;
    /* Collapsed width */
    height: 100vh;
    background-color: #333;
    transition: width 0.3s ease;
    z-index: 999;
}

.nav-item {
    display: flex;
    color: white;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    text-decoration: none;
    padding: 12px;
    transition: color 0.3s ease;
    margin: 7px;
}

.icon {
    font-size: 19px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.nav-label {
    opacity: 0;
    white-space: nowrap;
    transition: opacity 0.3s ease;
}

/* Sidebar expands with labels on hover for desktop */
@media (min-width: 769px) {

    .sidebar:hover {
        width: 200px;
    }

    .sidebar:hover .nav-label {
        opacity: 1;
    }
}

/* Hamburger menu for mobile */
.menu-btn {
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgb(69, 69, 69);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    transition: background-color 0.3s ease;
}

.menu-btn__burger {
    width: 25px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
    transform: translateY(-8px);
}

.menu-btn__burger::after {
    transform: translateY(8px);
}

/* Hamburger animation */
.menu-btn.open .menu-btn__burger {
    transform: rotate(45deg);
}

.menu-btn.open .menu-btn__burger::before,
.menu-btn.open .menu-btn__burger::after {
    transform: rotate(90deg);
    top: 0;
}

/* Full-screen blur overlay */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    z-index: 998;
}

/* Mobile menu */
.main-menu {
    display: none;
}

.main-menu.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.9);
    justify-content: center;
    z-index: 999;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .nav-item {
        justify-content: center;

    }

    .sidebar {
        display: none;
        /* Hide sidebar on mobile */
    }

    .menu-btn {
        display: flex;
        /* Show hamburger menu on mobile */
    }
}

.nav-item {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    text-decoration: none;

    transition: background-color 0.3s ease;
    transition: transform 0.1s ease;
    /* Smooth transition */

    width: 40px;
    /* Icon button size */
    height: 40px;
    position: relative;
    /* Ensures the icon stays in the same position */
}

.nav-item:hover {
    transform: translateX(5px);
    /* Shift to the right on hover */
}



/* Dark Mode toggle position */
.dark-mode-toggle {
    position: absolute;
    bottom: 3vh;
    /* Distance from the bottom of the sidebar */
    left: 50%;
    /* Horizontally center it */
    transform: translateX(-50%);
    /* Adjust to truly center */
}

/* Keep the toggle fixed in the center on hover */
.sidebar:hover .dark-mode-toggle {
    transform: translateX(-50%);
    /* Center it without moving */
}
