
.sec-1 {
    width: 100vw;
}

.head-text {
    z-index: -100;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #222;
    font-size: 10vh;
    filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
    cursor: context-menu;
}


.bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: rgba(51, 51, 51, 0.8);
    /* Light blue color */
    border-radius: 50%;
    /* Make it circular */
    filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
    transition: left 1s ease, top 1s ease;
    /* Smooth transition for position */
    animation: float 5s ease-in-out infinite;
    /* Add float animation */
}

.bubble-text {
    color: white;
    margin: auto;
}

/* Bubble floating animation */
@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
        /* Move up */
    }

    100% {
        transform: translateY(0);
        /* Back to original position */
    }
}