
:root {
  font-family: Helvetica, Arial, sans-serif;
  --text: #000;
  --accent: #191a23;
  --text-top-accent: #fff;
}

* {
  transition: all .2s;
}

body {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #222;
  overflow: hidden;
}

section {
  width: 50vw;
  height: 100vh;
}

.inv {
  color: #fff;
  mix-blend-mode: difference;
}

div#logo {
  display: grid;
  place-items: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 50%);
}

div#logo img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

section div.content {
  display: grid;
  align-items: center;
  place-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

section div.bg {
  position: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(10px);
}

body:hover>section:not(:hover) {
  width: 40vw;
}

section:hover {
  width: 60vw;
}

section:hover div.bg {
  filter: blur(0px);
}

section:hover div.content a.button {
  scale: 1.2;
}

section:hover a.button {
  filter: brightness(50%);
}

section a.button:hover {
  filter: brightness(50%);
}

section#chat div.bg {
  background-image: url('/src/assets/img/foto\ 6x6.png');
}

section#forum div.bg {
  background-image: url('/src/assets/img/background_handtekening.png');
}

a {
  text-decoration: none;
}

a.button {
  text-decoration: none;
  font-size: 200%;
  font-weight: bold;
  color: var(--text-top-accent);
  background-color: var(--accent);
  padding: 3vh 2vw;
  border-radius: 10px;
}

.site-name {
  text-decoration: none;
  color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #333;
  display: inline-block;
  margin: 10px 0;
}


a.button:hover {

  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(255, 255, 255),
    0 0 82px rgb(255, 255, 255),
    0 0 92px rgb(255, 255, 255),
    0 0 102px rgb(255, 255, 255),
    0 0 151px rgb(255, 255, 255);

}

a {
  text-decoration: none;
  display: inline-block; 
}

div {
  display: flex; 
}

